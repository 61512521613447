import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../../../../../environments/environment';
import { Observable } from 'rxjs';
import { NAMED_ENTITIES } from '@angular/compiler';

@Injectable({
  providedIn: 'root'
})
export class UmbralesService {

    head: HttpHeaders;
    constructor(
        private http: HttpClient,
    ) {
        this.head = new HttpHeaders();
        this.head = this.head.append('Authorization', 'Basic ' + btoa(environment.UsrAccessAuthAB + ':' + environment.PassAccessAuthAB));
    }
    //getters
    public getTipoCultivo_cbo(Ruc: string) {
        return this.http.get(`${environment.baseUrlPS}/Fitosanidad/ZABG_ListarTiposCultivo?prmstrRUCEmpresa=${Ruc}`, { headers: this.head }).pipe( map( data => data ));
    }
    public getCartillasActivas_cbo(idCartilla: number,idTipoCultivo: number, strTipoEval: string, Ruc: string ) {
        return this.http.get(`${environment.baseUrlPS}/Maestros/ZABG_ListarCartillasActivas?prmintCartilla=${idCartilla}`+
        `&prmintTipocultivo=${idTipoCultivo}`+
        `&prmstrTipoeval=${strTipoEval}`+
        `&RUCEmpresa=${Ruc}`, { headers: this.head }).pipe( map( data => data ));
    }
    public getListaUmbrales_table(idTipoCultivo: number, idCartilla: number, Ruc: string) {
        return this.http.get(`${environment.baseUrlPS}/Fitosanidad/ZABG_ListarUmbrales?prmintTipoCultivo=${idTipoCultivo}`+
        `&prmintCartilla=${idCartilla}`+
        `&prmstrRUCEmpresa=${Ruc}`, 
        { headers: this.head }).pipe( map( data => data ));
    }
    public enable_disable_umbral(idUmbral: number, usser: string) {
        return this.http.get(`${environment.baseUrlPS}/Fitosanidad/ZABG_EnableDisableUmbrales?prmintUmbral=${idUmbral}`+
        `&usser=${usser}`, 
        { headers: this.head }).pipe( map( data => data ));
    }
    

    public getTipoUmbrales_cbo() {
        return this.http.get(`${environment.baseUrlPS}/Fitosanidad/ZABG_ListarTiposUmbrales`, { headers: this.head }).pipe( map( data => data ));
    }
    public getGrupoVariable_cbo(idCartilla: number, RUC: string) {
        return this.http.get(`${environment.baseUrlPS}/Maestros/ZABG_ListarGruposCartilla?prmintCartilla=${idCartilla}&prmstrRUCEmpresa=${RUC}`, { headers: this.head }).pipe( map( data => data ));
    }
    public getVariable_cbo(idGrupo: number, RUC: string) {
        return this.http.get(`${environment.baseUrlPS}/Maestros/ZABG_ListarVariablesGrupo?prmintGrupo=${idGrupo}&prmstrRUCEmpresa=${RUC}`, { headers: this.head }).pipe( map( data => data ));
    }
    public getFases_cbo(RUC: string) {
        return this.http.get(`${environment.baseUrlPS}/Fitosanidad/ZABG_ListarFases?RUCEmpresa=${RUC}`, { headers: this.head }).pipe( map( data => data ));
    }
    public getFenologias_cbo(strFase: string, RUC: string) {
        return this.http.get(`${environment.baseUrlPS}/Fitosanidad/ZABG_ListarCboFenologias?prmstrFase=${strFase}&prmstrRUCEmpresa=${RUC}`, { headers: this.head }).pipe( map( data => data ));
    }
    public _GetIndicadorUmbralesList() {
        return this.http.get(`${environment.baseUrlPS}/Maestros/ZABG_GetIndicadorUmbralesList`, { headers: this.head }).pipe( map( data => data ));
    }
    

    save_umbral(model): Observable<any> {
		return this.http.post<any>(`${environment.baseUrlPS}/Fitosanidad/ZABS_RegistrarUmbral`, model, { headers: this.head });
    }
    update_umbral(model): Observable<any> {
		return this.http.post<any>(`${environment.baseUrlPS}/Fitosanidad/ZABS_EditarUmbral`, model, { headers: this.head });
    }



    
    
}
