import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../../../../../environments/environment';
import { Observable } from 'rxjs';
import { PersonalModel } from '../models/personal.model';

@Injectable({
  providedIn: 'root'
})
export class EmpleadoService {

  head: HttpHeaders;
  constructor(
    private http: HttpClient,
  ) {
      this.head = new HttpHeaders();
      this.head = this.head.append('Authorization', 'Basic ' + btoa(environment.UsrAccessAuthHCM + ':' + environment.PassAccessAuthHCM));
  }
  /*
  public ListarPersonal(Ruc: string) {
	  return this.http.get(`${environment.baseUrlGCH}/Personal/ZHCMG_ListarPersonal?prmstrNroPersonal=&prmstrDNI=&prmstrNombres=&prmstrRUCEmpresa=${Ruc}`).pipe( map( data => data ));
  }*/
  public getRolIntructivo() {
    return this.http.get(`${environment.baseUrlGCH}/Personal/ZHCMG_getRolIntructivo`, { headers: this.head});
  }
  public getRolUsuarioIntructivo_byId(usuario_id) {
    return this.http.get(`${environment.baseUrlGCH}/Personal/ZHCMG_getRolUsuarioIntructivo_byId?Usuario=${usuario_id}`, { headers: this.head});
  }
  saveRolIntructivoUsuario(id_usuario,rol_instr,user): Observable<any> {
    return this.http.post<PersonalModel>(`${environment.baseUrlGCH}/Personal/ZHCMG_saveRolIntructivoUsuario`, 
        {Usuario: id_usuario, Rol: rol_instr, UserSave: user}, { headers: this.head });
  }

  GuardarPersonal(personal): Observable<PersonalModel> {
		return this.http.post<PersonalModel>(`${environment.baseUrlGCH}/Personal/ZHCMG_GuardarPersonal`, personal, { headers: this.head });
  }

  UpdatePersonal(personal): Observable<PersonalModel> {
		return this.http.post<PersonalModel>(`${environment.baseUrlGCH}/Personal/ZHCMG_EditarPersonal`, personal, { headers: this.head });
  }

  public CambiarEstadoPersonal(Personal: string, Usuario: string) {
    return this.http.post(`${environment.baseUrlGCH}/Personal/ZHCMG_AnularPersonal`, { personalid: Personal, usuario: Usuario }, { headers: this.head});
  }

  public ListarPersonal(Ruc: string) {
    return this.http.get(`${environment.baseUrlGCH}/Personal/ZHCMG_ListarPersonal?prmstrNroPersonal=&prmstrDNI=&prmstrNombres=&prmstrRUCEmpresa=${Ruc}`
    ,{ headers: this.head }).pipe( map( data => data ));
  }
  public getPersonalData_by_DNI(nroPersonal: string,dni: string, ruc: string){
      return this.http.get(`${environment.baseUrlGCH}/Personal/ZHCMG_ListarPersonal?prmstrNroPersonal=${nroPersonal}&prmstrDNI=${dni}&prmstrNombres=&prmstrRUCEmpresa=${ruc}`,{ headers: this.head }).pipe( map( data => data ));
  }


  public saveFirmaPersonal(b64,DNI,USUARIO,ruc){
    return this.http.post(`${environment.baseUrlGCH}/Personal/ZHCMG_saveFirmaPersonal`,{NroPersonal: DNI, RUC: ruc, B64: b64, Usser: USUARIO},{ headers: this.head }).pipe( map( data => data ));
  }
  public getFirma_64(dni,ruc){
    return this.http.get(`${environment.baseUrlGCH}/Personal/ZHCMG_getFirma64?prmstrNroPersonal=${dni}&prmstrRUC=${ruc}`,{ headers: this.head }).pipe( map( data => data ));
  }
  
}

