import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../../../../../environments/environment';
import { Observable } from 'rxjs';
import { NAMED_ENTITIES } from '@angular/compiler';

@Injectable({
  providedIn: 'root'
})
export class LMR_Service {

    head: HttpHeaders;
    constructor(
        private http: HttpClient,
    ) {
        this.head = new HttpHeaders();
        this.head = this.head.append('Authorization', 'Basic ' + btoa(environment.UsrAccessAuthAB + ':' + environment.PassAccessAuthAB));
    }
    public getTipoCultivo_cbo(ruc: string){
        return this.http.get(`${environment.baseUrlPS}/Fitosanidad/ZABG_ListarTiposCultivo?prmstrRUCEmpresa=${ruc}`, 
        { headers: this.head }).pipe( map( data => data ));
      }
    public GetColorUmbralLMR(){
        return this.http.get(`${environment.baseUrlPS}/LimiteMaterialResidual/ZABG_GetColorUmbralLMR`, 
        { headers: this.head }).pipe( map( data => data ));
    }  
    public getMercado_cbo(ruc){
        return this.http.get(`${environment.baseUrlPS}/LimiteMaterialResidual/ZABG_ListarMercado?ruc=${ruc}`, 
        { headers: this.head }).pipe( map( data => data ));
    }
    public getLMR_List(Ruc: string) {
        return this.http.get(`${environment.baseUrlPS}/LimiteMaterialResidual/ZABS_getLMR_List?ruc=${Ruc}`, { headers: this.head }).pipe( map( data => data ));
    }
    public getLRMUmbral_List(Ruc: string) {
        return this.http.get(`${environment.baseUrlPS}/LimiteMaterialResidual/ZABS_getLRMUmbral_List?ruc=${Ruc}`, { headers: this.head }).pipe( map( data => data ));
    }
    
    public saveUpdateLMR(idLMR, TipoCultivo,  IngredienteActivo, NombreComercial, Mercado, LMR ,RUC: string, usuario: string ) {
        return this.http.post(`${environment.baseUrlPS}/LimiteMaterialResidual/ZABS_saveUpdateLMR`,{
            idLMR: idLMR,
            TipoCultivo: TipoCultivo,
            IngredienteActivo: IngredienteActivo,
            NombreComercial: NombreComercial,
            Mercado: Mercado,
            LMR: LMR,
            ruc: RUC,
            usuario: usuario
        }, { headers: this.head }).pipe( map( data => data ));
    }
    public saveUpdateLMR_Umbral(idUmbralLMR, idLMR,  Indicador, minimo, maximo, usuario: string ) {
        return this.http.post(`${environment.baseUrlPS}/LimiteMaterialResidual/ZABS_saveUpdateLMR_Umbral`,{
            idUmbralLMR: idUmbralLMR,
            idLMR: idLMR,
            Indicador: Indicador,
            Minimo: minimo,
            Maximo: maximo,
            usuario: usuario
        }, { headers: this.head }).pipe( map( data => data ));
    }
    public enableDisable_LMR(id: number, future_status: boolean, usuario: string) {
        return this.http.post(`${environment.baseUrlPS}/LimiteMaterialResidual/ZABS_enableDisable_LMR`,{
            id: id,
            future_status:future_status,
            usuario:usuario 
        }, { headers: this.head }).pipe( map( data => data ));
    }
    public enableDisable_LMR_Umbral(id: number, future_status: boolean, usuario: string) {
        return this.http.post(`${environment.baseUrlPS}/LimiteMaterialResidual/ZABS_enableDisable_LMR_Umbral`,{
            id: id,
            future_status:future_status,
            usuario:usuario 
        }, { headers: this.head }).pipe( map( data => data ));
    }
    public MigracionLMR(ruc, usuario: string, array_Data ) {
        return this.http.post(`${environment.baseUrlPS}/LimiteMaterialResidual/ZABG_MigracionLMR`,{
            DATA: array_Data,
            ruc: ruc,
            usuario: usuario
        }, { headers: this.head }).pipe( map( data => data ));
    }
}
